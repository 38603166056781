import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-van-gogh-pintura-amor-e-propósito",
      "style": {
        "position": "relative"
      }
    }}>{`🎨 Van Gogh: Pintura, amor e propósito`}<a parentName="h1" {...{
        "href": "#-van-gogh-pintura-amor-e-prop%C3%B3sito",
        "aria-label": " van gogh pintura amor e propósito permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`Cumprindo sua função`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`completamente`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`um barco vazio descansa`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`contra os juncos,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`pastoreando a lua`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`como as palavras certas fluem`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`fora da mente, deixando`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`águas suaves contra a madeira,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`pincéis de vida sem limites.`}</em>{`  `}</p>
        <p><img parentName="p" {...{
            "src": "/images/mod0-img/vangoghselfportrait.jpg",
            "alt": "Van Gogh"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://www.themarginalian.org/2014/12/01/van-gogh-purpose-letter/" mdxType="Button">Ame o que você faz</Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h2 {...{
      "id": "como-isso-se-aplica-ao-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`Como isso se aplica ao Kernel?`}<a parentName="h2" {...{
        "href": "#como-isso-se-aplica-ao-kernel",
        "aria-label": "como isso se aplica ao kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Terminaremos nossa introdução ao Kernel com uma carta enviada por Vincent Van Gogh a seu irmão em 1879. Ao longo deste livro, descreveremos várias maneiras de pensar sobre propósito, intenção e viver honestamente uma vida plena e saudável. Van Gogh apontou esses caminhos de forma mais pungente e em grande parte sem palavras, habilidade pela qual o amamos profundamente. Esperamos que o resumo a seguir o ajude:`}</p>
    <Process mdxType="Process">
      <p>{`Faça perguntas melhores`}</p>
      <p>{`Pense profundamente sobre sua intenção`}</p>
      <p>{`Reflita sobre sua definição de 'valor' e 'confiança'`}</p>
      <p>{`Pense no que você, pessoalmente, está fazendo aqui? Qual é a sua pergunta?`}</p>
    </Process>
    <h2 {...{
      "id": "resumo",
      "style": {
        "position": "relative"
      }
    }}>{`Resumo`}<a parentName="h2" {...{
        "href": "#resumo",
        "aria-label": "resumo permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`"A melhora na minha vida - não devo desejá-la ou não devo precisar de mudança? Eu realmente quero melhorar. Mas é justamente por ansiar por isso que tenho medo de remédios piores que a doença."`}</p>
    </blockquote>
    <p>{`Como lidamos com essa tensão entre melhorar e ser alegremente quem já somos?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Esse tipo de marcha lenta é realmente um tipo muito estranho de marcha lenta. É difícil para mim me defender nesse aspecto, mas lamentaria se você não pudesse ver isso por outra perspectiva."`}</p>
    </blockquote>
    <p>{`Van Gogh apresenta a TINKERING / bricolagem (movimento independente de melhoria ou gambiarra) como uma maneira melhor de ser. Para nós atualmente, pode não ser sobre construir mais produtos para 'consumidores', mas sim usar novos `}<a parentName="p" {...{
        "href": "/learn/module-0/trust"
      }}>{`espaços de confiança`}</a>{` para comungar de uma forma diferente e digital.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Tal pessoa nem sempre sabe o que poderia fazer, mas sente por instinto: sirvo para alguma coisa, mesmo assim! Sinto que tenho uma razão de ser! Sei que poderia ser uma pessoa diferente! Para que então eu poderia ser útil, para que eu poderia servir! Há algo dentro de mim, então, `}<strong parentName="p">{`o que é isso`}</strong>{`?"`}</p>
    </blockquote>
    <p>{`O que está no seu coração? Você pode se perguntar o quão relevante é esta questão, mas não podemos construir uma web melhor se não entendermos primeiro o que pode significar sermos melhores. E isso requer uma investigação profunda sobre a natureza do eu: não há outro caminho.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Mas qual é o seu objetivo final, você dirá. O objetivo ficará mais claro, tomará forma lenta e seguramente, à medida que o croqui se torna um esboço e o esboço uma pintura, à medida que se trabalha com mais seriedade, que se aprofunda na ideia originalmente vaga, no primeiro pensamento fugaz, passageiro."`}</p>
    </blockquote>
    <p>{`Você confia na voz interior? Novamente, uma questão aparentemente abstrata com profundas implicações para o que realmente, na prática, entendemos como sendo a confiança; que se reflete diretamente nos tipos de produtos que construímos.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Sempre estou inclinado a acreditar que a melhor forma de conhecer algo é amá-la muito. Ame aquele amigo, aquela pessoa, aquela coisa, ame o que quiser, e você estará no caminho certo para conhecer o objeto de seu amor mais a fundo; é o que eu digo a mim mesmo. Mas você deve amar com muita empatia e seriedade, com vontade, com inteligência, você deve sempre procurar saber melhor e mais profundamente.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Pergunta: Qual é o pré-requisito para construir uma web melhor juntos?`}</p>
        <p>{`Saber como eu mesmo poderia ser melhor.`}</p>
      </Card>
    </Flash>
    <hr></hr>
    <p>{`Se você gostou deste artigo e quer tropeçar em outros Brainpickings por aqui, recomendamos que comece com isso:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Esperança`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod0-img/impossible.jpg",
            "alt": "Vaclav Havel"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://www.themarginalian.org/2019/09/22/vaclav-havel-hope/" mdxType="Button">
    Leia-o
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      